import React from "react";
import Layout from "../../components/Layout";
import ScrollEffect from "../../utils/ScrollEffect";

//Styles
import "./style.scss";

const ContactUs = () => {
  return (
    <Layout>
      <ScrollEffect className={"contactUsContainer"}>
        <h2>Contact us</h2>
        <hr />
      </ScrollEffect>
    </Layout>
  );
};

export default ContactUs;

import React from "react";
import Layout from "../../components/Layout";
import ScrollEffect from "../../utils/ScrollEffect";

//Styles
import "./style.scss";

const About = () => {
  return (
    <Layout>
      <ScrollEffect className={"aboutContainer"}>
        <h2>About us</h2>
        <hr />
        <div className="content">
          <p>
            At <span>Lee Group For Petroleum Services</span>, we are a reputable
            and leading provider of innovative petroleum services, committed to
            delivering exceptional solutions to the global energy industry. With
            an unwavering focus on technology, safety, and sustainability, we
            strive to set new standards and exceed customer expectations.
          </p>
          <p>
            Our team of highly skilled professionals possesses unparalleled
            expertise in various areas of the petroleum sector . We offer an
            extensive range of services, including but not limited to Wireline,
            Well Control and Inspection Services.
          </p>
          <p>
            As a company, we firmly believe in the power of innovation. We
            continuously invest in research and development to stay at the
            forefront of technological advancements, allowing us to provide our
            clients with cutting-edge solutions that enhance operational
            efficiency, maximize production, and reduce environmental impact.
          </p>
          <p>
            Safety is a core value that underpins everything we do. We adhere to
            stringent safety protocols, ensuring the well-being of our
            employees, clients, and the communities we operate in. Through
            comprehensive training, regular inspections, and continuous
            improvement initiatives, we are committed to achieving an
            incident-free workplace.
          </p>
          <p>
            In summary, <span>Lee Group For Petroleum Services</span> is your
            trusted partner for all your petroleum service needs. With our
            dedication to excellence, innovation, safety, and sustainability, we
            are committed to delivering superior solutions that drive success in
            the ever-evolving energy landscape.
          </p>
        </div>
      </ScrollEffect>
    </Layout>
  );
};

export default About;

import React from "react";
import { NavLink } from "react-router-dom";
import ScrollEffect from "../../utils/ScrollEffect";
import Contact from "../Contact";

//Icons
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebook, FaLinkedin } from "react-icons/fa";

//Styles
import "./style.scss";

const Footer = () => {
  return (
    <div className="footerContainer">
      <ScrollEffect className={"contactContainer"}>
        <Contact />
      </ScrollEffect>
      <ScrollEffect className={"footer"}>
        <div className="logoContainer">
          <img src="/images/logoTrans.png" alt="" srcset="" />
        </div>
        <div className="links">
          <div className="nav">
            <NavLink exact activeClassName="active" to="/">
              Home
            </NavLink>
          </div>
          {/* <div className="nav">
            <NavLink exact activeClassName="active" to={"/services"}>
              Services
            </NavLink>
          </div> */}
          <div className="nav">
            <NavLink exact activeClassName="active" to={"/about-us"}>
              About Us
            </NavLink>
          </div>
          <div className="nav">
            <NavLink exact activeClassName="active" to={"/why-us"}>
              Why us
            </NavLink>
          </div>
          <div className="nav">
            <NavLink exact activeClassName="active" to={"/contact-us"}>
              Contact us
            </NavLink>
          </div>
        </div>
        <div className="social">
          <div className="icon">
            <FaFacebook size={24} color="#dd5b00" cursor={"pointer"} />
          </div>
          <div className="icon">
            <AiFillInstagram size={24} color="#dd5b00" cursor={"pointer"} />
          </div>
          <div className="icon">
            <FaLinkedin size={24} color="#dd5b00" cursor={"pointer"} />
          </div>
        </div>
      </ScrollEffect>
    </div>
  );
};

export default Footer;

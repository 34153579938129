// ScrollEffectSection.js
import React, { useEffect, useRef } from "react";

//Styles
import "./style.scss";

const ScrollEffect = ({ children, className }) => {
  const sectionRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            sectionRef.current.classList.add("visible");
          } else {
            sectionRef.current.classList.remove("visible");
          }
        });
      },
      { threshold: 0.05 } // Adjust the threshold as needed
    );

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section ref={sectionRef} className={className}>
      {children}
    </section>
  );
};

export default ScrollEffect;

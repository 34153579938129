import React, { useState } from "react";

//Icons
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";

//Styles
import "./style.scss";

const Contact = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleSendEmail = () => {
    const { name, email, phoneNumber, message } = data;

    // Construct the mailto link
    const mailtoLink = `mailto:tarek.saleh@leeoilservices.com?subject=Contact%20Form&body=Name:%20${encodeURIComponent(
      name
    )}%0AEmail:%20${encodeURIComponent(
      email
    )}%0APhone%20Number:%20${encodeURIComponent(
      phoneNumber
    )}%0AMessage:%20${encodeURIComponent(message)}`;

    // Open the user's default email client
    window.location.href = mailtoLink;
  };

  const handleCallClick = (phoneNumber) => {
    const telLink = `tel:${phoneNumber}`;
    window.location.href = telLink;
  };

  return (
    <div className="contact">
      <div className="container">
        <div className="details">
          <div>
            <div className="icon">
              <FiPhone size={24} color="#dd5b00" />
              Call Us
            </div>
            <div
              className="data"
              onClick={() => handleCallClick("+20 01019841175")}
            >
              +20 01019841175
            </div>
            <div
              className="data"
              onClick={() => handleCallClick("+20 1007125147")}
            >
              +20 1007125147
            </div>
            <div
              className="data"
              onClick={() => handleCallClick("+20 1017670264")}
            >
              +20 1017670264
            </div>
          </div>
          <div>
            <div className="icon">
              <SlLocationPin size={24} color="#dd5b00" />
              Location
            </div>
            <div className="data">
              Villa 14, District 9, 1st settlement, New Cairo, Egypt.
            </div>
          </div>
          <div>
            <div className="icon">
              <MdOutlineMailOutline size={24} color="#dd5b00" />
              Mail Us
            </div>
            <div
              className="data"
              onClick={() => {
                window.location.href = `mailto:tarek.saleh@leeoilservices.com`;
              }}
            >
              tarek.saleh@leeoilservices.com
            </div>
          </div>
        </div>
        <div className="form">
          <div className="container">
            <h1 className="title">
              Contact <span>Us</span>
            </h1>
            <hr />
            <form>
              <input
                type="text"
                placeholder="Name"
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
              <input
                type="email"
                placeholder="Email"
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
              <input
                type="text"
                placeholder="Phone Number"
                value={data.phoneNumber}
                onChange={(e) =>
                  setData({ ...data, phoneNumber: e.target.value })
                }
              />
              <textarea
                placeholder="Message"
                value={data.message}
                onChange={(e) => setData({ ...data, message: e.target.value })}
              ></textarea>
              <button type="submit" onClick={handleSendEmail}>
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import Layout from "../../components/Layout";
import ScrollEffect from "../../utils/ScrollEffect";

//Styles
import "./style.scss";

const WhyUs = () => {
  return (
    <Layout>
      <ScrollEffect className={"whyUsContainer"}>
        <h2>Why Choose Lee Group`s Services?</h2>
        <hr />
        <div className="content">
          <p>
            Choosing Lee Group for Petroleum Services comes with a myriad of
            advantages, as our commitment to excellence, innovation, and safety
            sets us apart in the oil and gas industry. Here are compelling
            reasons to choose Lee Group's services:
          </p>
          <p>
            <span>1- Safety First:</span>
            <br />
            Safety is non-negotiable in the oil and gas sector, and Lee Group
            prioritizes it above all else. Our stringent safety protocols,
            compliance with industry standards, and commitment to best practices
            ensure the well-being of personnel, assets, and the environment.
          </p>
          <p>
            <span>2- Customized Solutions:</span>
            <br />
            Recognizing the uniqueness of each project, Lee Group collaborates
            closely with clients to develop customized solutions that align with
            specific objectives and challenges. Whether it's downhole services,
            inspections, wireline operations, or machine shop services, our
            approach is tailored to your needs.
          </p>
          <p>
            <span>3- Comprehensive Service Portfolio:</span>
            <br />
            Lee Group offers a comprehensive range of services covering the
            entire spectrum of the oil and gas industry. From downhole
            interventions to blowout preventers, wireline solutions, machine
            shop capabilities, and inspection services, we provide a
            one-stop-shop for diverse requirements.
          </p>
          <p>
            <span>4- Certified Quality:</span>
            <br />
            Operating under API and ISO certifications, Lee Group ensures that
            our services meet or exceed industry standards. Our commitment to
            certified quality provides clients with confidence in the
            reliability, safety, and performance of our offerings.
          </p>
          <p>
            Choosing Lee Group for Petroleum Services means choosing a partner
            dedicated to excellence, innovation, and safety. Contact us today to
            experience the Lee Group advantage and see how our services can
            contribute to the success of your oil and gas projects.
          </p>
        </div>
      </ScrollEffect>
    </Layout>
  );
};

export default WhyUs;

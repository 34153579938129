import React from "react";
import { Layout } from "../../components";
import ScrollEffect from "../../utils/ScrollEffect";

//Icons
import { ReactComponent as Mission } from "../../assets/svgs/mission.svg";
import { ReactComponent as Service } from "../../assets/svgs/service.svg";
import { ReactComponent as Values } from "../../assets/svgs/values.svg";
import { ReactComponent as Vision } from "../../assets/svgs/vision.svg";

//Styles
import "./style.scss";

const Home = () => {
  return (
    <Layout>
      <div className="homeContainer">
        <div className="coverContainer">
          <img src="/images/cover.jpeg" alt="" srcset="" />
          <div className="content">
            <h1>Lee Group</h1>
            <h3>For Petroleum Services</h3>
          </div>
        </div>
        <ScrollEffect className="aboutS">
          <h2>About us</h2>
          <hr />
          <div className="content">
            <p>
              At <span>Lee Group For Petroleum Services</span>, we are a
              reputable and leading provider of innovative petroleum services,
              committed to delivering exceptional solutions to the global energy
              industry. With an unwavering focus on technology, safety, and
              sustainability, we strive to set new standards and exceed customer
              expectations.
            </p>
            <p>
              Our team of highly skilled professionals possesses unparalleled
              expertise in various areas of the petroleum sector . We offer an
              extensive range of services, including but not limited to
              Wireline, Well Control and Inspection Services.
            </p>
            <div className="grid-container">
              <div className="grid-item">
                <div className="title">
                  <Vision
                    color="#dd5b00"
                    fill="#dd5b00"
                    width={50}
                    height={50}
                  />
                  <h3>Our Vision</h3>
                </div>
                <div className="content">
                  Our Vision is to be a globally recognized leader in petroleum
                  services, delivering innovative solutions and setting industry
                  standards through excellence in technology , safety , and
                  sustainability .
                </div>
              </div>
              <div className="grid-item">
                <div className="title">
                  <Mission
                    color="#dd5b00"
                    fill="#dd5b00"
                    width={50}
                    height={50}
                  />
                  <h3>Our Mission</h3>
                </div>
                <div className="content">
                  Our Mission is to provide comprehensive and reliable petroleum
                  services, utilizing cutting-edge technologies, while
                  maintaining a steadfast commitment to safety , environmental
                  stewardship, and customer satisfaction. We aim to foster
                  long-term partnerships with clients, empower our employees,
                  and contribute positively to the communities where we operate.
                </div>
              </div>
              <div className="grid-item">
                <div className="title">
                  <Values
                    color="#dd5b00"
                    fill="#dd5b00"
                    width={50}
                    height={50}
                  />
                  <h3>Our Values</h3>
                </div>
                <div className="content">
                  <span>Excellence:</span> We strive for excellence in all
                  aspects of our work, delivering superior services and
                  exceeding customer expectations.
                  <br />
                  <span>Safety:</span> We prioritize the safety of our
                  employees, customers, and communities, adhering to strict
                  safety protocols and promoting a culture of incident-free
                  operations.
                  <br />
                  <span>Integrity:</span> We conduct business with honesty ,
                  transparency , and ethical practices, building trust and
                  maintaining long-term relationships with our stakeholders.
                  <br />
                  <span>Collaboration:</span> We foster collaboration and
                  teamwork, engaging with our employees, partners, and clients
                  to achieve shared success and create value for all
                  stakeholders.
                </div>
              </div>
            </div>
          </div>
        </ScrollEffect>
        <ScrollEffect className="servicesS">
          <h2>Services</h2>
          <hr />
          <div className="content">
            <div className="grid-container">
              <div className="grid-item">
                <div className="container">
                  <div className="title">
                    <Service width={50} height={50} />
                    <h3>Downhole Services</h3>
                    <h5>Precision Solutions for Well Optimization</h5>
                  </div>
                  <div className="content">
                    At Lee Group for Petroleum Services, our Downhole Services
                    are designed to address the intricacies of downhole
                    operations with precision and efficiency. We offer a
                    comprehensive range of solutions
                  </div>
                </div>
              </div>
              <div className="grid-item">
                <div className="container">
                  <div className="title">
                    <Service width={50} height={50} />
                    <h3>Wireline Services</h3>
                    <h5>
                      Precision and Efficiency for Optimal Well Performance
                    </h5>
                  </div>
                  <div className="content">
                    Lee Group for Petroleum Services is a leader in Wireline
                    Services, delivering precision solutions that play a pivotal
                    role in optimizing well performance and enhancing the
                    overall efficiency of your oil and gas operations.
                  </div>
                </div>
              </div>
              <div className="grid-item">
                <div className="container">
                  <div className="title">
                    <Service width={50} height={50} />
                    <h3>Inspection Services</h3>
                    <h5>Ensuring Integrity, Safety, and Compliance</h5>
                  </div>
                  <div className="content">
                    Lee Group for Petroleum Services is your trusted partner in
                    Inspection Services, offering a diverse range of solutions
                    to guarantee the integrity, safety, and compliance of your
                    equipment and operations in the oil and gas industry.
                  </div>
                </div>
              </div>
              <div className="grid-item">
                <div className="container">
                  <div className="title">
                    <Service width={50} height={50} />
                    <h3>Blowout Preventers (BOP)</h3>
                    <h5>Safeguarding Your Operations</h5>
                  </div>
                  <div className="content">
                    Lee Group for Petroleum Services takes pride in offering a
                    comprehensive suite of Blowout Preventers (BOP) and
                    associated equipment, designed to ensure the highest level
                    of safety and reliability in your oil and gas operations.
                  </div>
                </div>
              </div>
              <div className="grid-item">
                <div className="container">
                  <div className="title">
                    <Service width={50} height={50} />
                    <h3>Machine Shop</h3>
                    <h5>Precision Engineering for Oilfield Excellence</h5>
                  </div>
                  <div className="content">
                    Our core business is based on the rental and fabrication of
                    a wide range of oilfield tools in addition to rethreading
                    and repair of most of the drill pipes and other tubular as
                    per API and ISO certification
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollEffect>
        <ScrollEffect className="policiesS">
          <h2>Policies</h2>
          <hr />
          <div className="content">
            <div className="subSection">
              <h3>Environmental Policy</h3>
              <div className="data">
                <ul>
                  <li>
                    Limiting waste generation, discharges, and emissions and
                    handling waste in a responsible manner.
                  </li>
                  <li>
                    Minimize spills, leaks, and accidental discharges while
                    conducting operations.
                  </li>
                  <li>
                    Usage of energy and natural resources in the most efficient
                    way.
                  </li>
                  <li>
                    Preparation of instructions and equipment for handling
                    environmental emergencies.
                  </li>
                </ul>
              </div>
            </div>
            <div className="subSection">
              <h3>Safety Policy</h3>
              <div className="data">
                <ul>
                  <li>
                    Ongoing hazard identification, risk assessment, and control
                    to prevent/reduce hazards and accidents.
                  </li>
                  <li>
                    Cooperation with all employees, clients, suppliers, and
                    contractors to meet, exceed or develop all necessary or
                    desirable requirements.
                  </li>
                  <li>
                    Maintaining an audited management system which ensures that
                    the Health & Safety Policy is in compliance with all
                    relevant statutes and regulations.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ScrollEffect>
      </div>
    </Layout>
  );
};

export default Home;

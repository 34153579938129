import React, { useState } from "react";
import { NavLink } from "react-router-dom";

// Styles
import "./style.scss";

const Navbar = () => {
  // State to manage the visibility of the mobile menu
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Function to toggle the mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div
      className={`navbarContainer ${isMobileMenuOpen ? "mobileMenuOpen" : ""}`}
    >
      <div className="container">
        <div className="logoContainer">
          <div>
            <img src="/images/logo.jpeg" alt="" />
          </div>
        </div>

        {/* Burger menu icon for smaller screens */}
        <div className="burgerMenu" onClick={toggleMobileMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>

        {/* Navigation links */}
        <div className={`links ${isMobileMenuOpen ? "mobileLinksOpen" : ""}`}>
          <div className="nav">
            <NavLink
              exact
              activeClassName="active"
              to="/"
              onClick={toggleMobileMenu}
            >
              Home
            </NavLink>
          </div>
          <div className="nav">
            <NavLink
              exact
              activeClassName="active"
              to="/about-us"
              onClick={toggleMobileMenu}
            >
              About Us
            </NavLink>
          </div>
          <div className="nav">
            <NavLink
              exact
              activeClassName="active"
              to="/why-us"
              onClick={toggleMobileMenu}
            >
              Why us
            </NavLink>
          </div>
          <div className="nav">
            <NavLink
              exact
              activeClassName="active"
              to="/contact-us"
              onClick={toggleMobileMenu}
            >
              Contact us
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
